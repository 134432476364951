<script setup>
const auth = useAuthStore()
const test = () =>{
  auth.testAuth()
}
</script>

<template>
    <div>
      <!-- This page correctly has only one single root element -->
      Page content
      <button @click="test">Test auth</button>
    </div>
  </template>